import { render, staticRenderFns } from "./nyptype.vue?vue&type=template&id=b615c972&scoped=true"
import script from "./nyptype.vue?vue&type=script&lang=js"
export * from "./nyptype.vue?vue&type=script&lang=js"
import style0 from "./nyptype.vue?vue&type=style&index=0&id=b615c972&prod&lang=less"
import style1 from "./nyptype.vue?vue&type=style&index=1&id=b615c972&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b615c972",
  null
  
)

export default component.exports